import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { IDropdownOption } from '@ui-modules/types';
import {
  Button,
  InputDatePicker,
  InputLocation,
  InputText,
  InputTimePicker,
  Select,
} from '@components';
import { GoogleSuggestion } from '@components/form/types';
import { DateInputFormat } from '@common/types';
import { Add } from '@assets/svg/icons';
import type { BFRoute } from '../types';

const LEG_LIMIT = 20;
const legCount = 0;

type SectionProps = {
  city?: string;
  country?: string;
  form: UseFormReturn<any>;
  init: BFRoute;
  purpose: IDropdownOption[];
  showGoogleLocation?: boolean;
  showTown?: boolean;
  onDateChange?: (idx: number) => void;
  onTimeChange?: (value: string, idx: number) => void;
};

const MultilegSection = ({
  city,
  country,
  form,
  init,
  purpose,
  showGoogleLocation,
  showTown,
  onDateChange,
  onTimeChange,
}: SectionProps) => {
  const { t } = useTranslation();
  const { control: ctrl, setValue, watch } = form;
  const { fields, append, remove } = useFieldArray({ control: ctrl, name: 'routes' });

  const handleLocationChange = (name: string) => (e: GoogleSuggestion | string) => {
    if (typeof e === 'object') {
      setValue?.(`${name}EqualFacility`, e.dataset?.isFacilityLocation);
      setValue?.(`${name}Town`, e.dataset?.town ?? city);
      setValue?.(`${name}Lat`, e.dataset?.lat ?? 0);
      setValue?.(`${name}Lng`, e.dataset?.lng ?? 0);
    }
  };

  return (
    <>
      {fields.map((leg, idx) => {
        const isDropoffLocExtraRequired = watch(`routes.${idx}.dropoffEqualFacility`);
        const isPickupLocExtraRequired = watch(`routes.${idx}.pickupEqualFacility`);

        return (
          <fieldset className="multileg" key={leg.id}>
            <legend data-testid={`bf-multileg-legend-${idx}`}>
              {`Leg ${idx + 1}`}
              {idx > 1 && (
                <Button
                  type="button"
                  className="multileg-btn-remove"
                  text={<div>&#215;</div>}
                  variant="transparent"
                  onClick={() => remove(idx)}
                />
              )}
            </legend>

            {(showGoogleLocation || showTown) && (
              <>
                <InputText
                  name={`routes.${idx}.pickupTown`}
                  className="field"
                  control={ctrl}
                  hidden={showGoogleLocation}
                  label={t('bookingDetails.pickupTown')}
                  required
                  data-testid={`bf-pickup-town-${idx}`}
                />
                <InputText
                  name={`routes.${idx}.dropoffTown`}
                  className="field"
                  control={ctrl}
                  hidden={showGoogleLocation}
                  label={t('bookingDetails.dropoffTown')}
                  required
                  data-testid={`bf-dropoff-town-${idx}`}
                />
              </>
            )}

            <InputDatePicker
              name={`routes.${idx}.pickupDate`}
              className="field"
              control={ctrl}
              hideOutsideDates
              label={t('bookingDetails.pickupDate')}
              minDate={new Date()}
              required
              valueFormat={DateInputFormat.DateByDots}
              onChange={() => onDateChange?.(idx)}
              data-testid={`bf-pickup-date-${idx}`}
            />
            <InputTimePicker
              name={`routes.${idx}.pickupTime`}
              className="field"
              control={ctrl}
              label={t('bookingDetails.pickupTime')}
              required
              onChange={(e) => onTimeChange?.(e.target.value, idx)}
              data-testid={`bf-pickup-time-${idx}`}
            />
            {showGoogleLocation ? (
              <>
                <InputLocation
                  name={`routes.${idx}.pickupLocation`}
                  className="field"
                  control={ctrl}
                  defaultCity={city}
                  defaultCountry={country}
                  label={`${t('bookingDetails.pickupLocation')} (Google Maps)`}
                  required
                  onChange={handleLocationChange(`routes.${idx}.pickup`)}
                  data-testid={`bf-pickup-location-${idx}`}
                />
                <InputLocation
                  name={`routes.${idx}.dropoffLocation`}
                  className="field"
                  control={ctrl}
                  defaultCity={city}
                  defaultCountry={country}
                  label={`${t('bookingDetails.dropoffLocation')} (Google Maps)`}
                  required
                  onChange={handleLocationChange(`routes.${idx}.dropoff`)}
                  data-testid={`bf-dropoff-location-${idx}`}
                />
                <InputText
                  name={`routes.${idx}.pickupLocExtra`}
                  className="field"
                  control={ctrl}
                  label={`${t('bookingDetails.pickupLocExtra')} (Manual Input${
                    isPickupLocExtraRequired ? '' : ', optional'
                  })`}
                  required={isPickupLocExtraRequired}
                />
                <InputText
                  name={`routes.${idx}.dropoffLocExtra`}
                  className="field"
                  control={ctrl}
                  label={`${t('bookingDetails.dropoffLocExtra')} (Manual Input${
                    isDropoffLocExtraRequired ? '' : ', optional'
                  })`}
                  required={isDropoffLocExtraRequired}
                />
              </>
            ) : (
              <>
                <InputText
                  name={`routes.${idx}.pickupLocation`}
                  className="field"
                  control={ctrl}
                  label={t('bookingDetails.pickupLocation')}
                  required
                  data-testid={`bf-pickup-location-${idx}`}
                />
                <InputText
                  name={`routes.${idx}.dropoffLocation`}
                  className="field"
                  control={ctrl}
                  label={t('bookingDetails.dropoffLocation')}
                  required
                  data-testid={`bf-dropoff-location-${idx}`}
                />
              </>
            )}
          </fieldset>
        );
      })}

      {legCount <= LEG_LIMIT && (
        <section className="multileg-ctrl">
          <Button
            className="multileg-btn-add"
            type="button"
            text={
              <>
                <Add /> {t('planner.addLeg')}
              </>
            }
            onClick={() => append(init)}
            data-testid="bf-add-leg-button"
          />
        </section>
      )}

      <fieldset>
        <Select
          name="bookingExtra.purpose"
          className="field"
          control={ctrl}
          label={t('common.purpose')}
          options={purpose}
          required
          data-testid="bf-booking-extra-purpose"
        />
        <InputText
          name="bookingExtra.remarks"
          className="field"
          control={ctrl}
          label={`${t('common.remarks')} (${t('common.optional')})`}
          data-testid="bf-booking-extra-remarks"
        />
      </fieldset>
    </>
  );
};

export default MultilegSection;
