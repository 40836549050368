/* istanbul ignore file */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import { useFormApi } from 'informed';
import { toast } from 'react-toastify';
import { t } from 'i18next';

import { IDropdownOption } from '@ui-modules/types';
import { TextField } from '@ui-modules/informed';
import { useRepository } from '@context';
import { DriveFormSteps } from '@common/interfaces';
import { useFieldState } from '@common/hooks';
import { emailValidator, getErrors, validateWithPax } from '@common/utils';
import '../styles/modal.scss';

interface AgencyOption extends IDropdownOption {
  isThirdParty?: boolean;
}

let agenciesOptions: AgencyOption[] = [];

const GuestEmailField: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const formApi = useFormApi();
  const { agenciesRepository } = useRepository();
  const { value: noPax } = useFieldState<boolean>('noPax');
  const { value: emailField } = useFieldState<string>('email');

  const formState = formApi.getFormState().values as any;
  const agencyField: IDropdownOption = formState?.[DriveFormSteps.Main]?.agency;

  const [selectedEmail, setSelectedEmail] = useState<string | undefined>();
  const [isFallback, setFallback] = useState<boolean>(false);

  const debouncedEmailCallback = useDebounce(selectedEmail, 500);

  const loadAgencyOption = async (domain?: string): Promise<void> => {
    try {
      const response = await agenciesRepository.getAgencies({
        ...(domain ? { domain: domain.split('@')[1].toLowerCase() } : {}),
        limit: 250,
      });

      agenciesOptions = response.results.map((agency) => ({
        label: agency.short_name,
        value: agency.uuid,
        isThirdParty: agency.domain === 'fallback-agency.org',
      }));
    } catch (error: any) {
      if (error.response) {
        toast.error(getErrors(error.response.data));
        agenciesOptions = [];
        formApi.setValue(`${DriveFormSteps.Main}.agency`, '');
      }
    }

    formApi.setValue('agenciesOptions', agenciesOptions);

    if (agenciesOptions?.length === 1 && agenciesOptions[0].isThirdParty) {
      setFallback(true);
      formApi.setValue(`${DriveFormSteps.Main}.agency`, '');
    } else if (agenciesOptions?.length > 1) {
      formApi.setValue(`${DriveFormSteps.Main}.agency`, '');
      setFallback(false);
    } else if (isFallback && domain) {
      setFallback(false);
    }
  };

  useEffect(() => {
    if (selectedEmail) loadAgencyOption?.(selectedEmail);
  }, [debouncedEmailCallback]);

  useEffect(() => {
    if (isFallback) loadAgencyOption?.();
  }, [isFallback]);

  useEffect(() => {
    const agencyExist = agenciesOptions.find((a) => a.label === agencyField?.label);

    if (!agencyExist && agencyField && emailField) {
      loadAgencyOption?.(emailField);
    }
  }, [agencyField]);

  return (
    <div className="field">
      <TextField
        className="input"
        data-testid="planner-drive-form-field-email"
        disabled={disabled || noPax}
        label={t('common.email')}
        name="email"
        validate={validateWithPax(emailValidator)}
        onChange={(formValue) => {
          const value = (formValue as any).value as string;

          if (value && typeof emailValidator(value) !== 'string') {
            setSelectedEmail(value);
          }
        }}
      />
    </div>
  );
};

export default GuestEmailField;
