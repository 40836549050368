import { type Dispatch, type SetStateAction, useEffect, useReducer, useState } from 'react';
import type { DatesRangeValue } from '@mantine/dates';
import { useQuery } from 'react-query';
import { FilterActionTypes } from '@common/constants';
import {
  DropdownOption,
  FilterAction,
  FilterParams,
  FilterSelected,
  FilterType,
  FiltersApi,
} from '@common/interfaces';
import { useFacility } from '@common/hooks';
import { useRepository } from '@context';
import { transferType, tripType } from '../utils';

const initFilterState: FilterType[] = [];

function filterReducer(state: FilterType[], action: FilterAction): FilterType[] {
  switch (action.type) {
    case FilterActionTypes.INIT:
      return action.payload.map(({ options, title }) => ({ options, title }));
    case FilterActionTypes.CLEAR_ALL:
      return state.map((f) => ({ ...f, selected: undefined }));
    case FilterActionTypes.CLEAR_ONE:
      return state.map((f) => (f.title === action.payload ? { ...f, selected: undefined } : f));
    case FilterActionTypes.CHANGE_FILTER:
      return state.map((f) =>
        f.title === action.payload.title ? { ...f, selected: action.payload.option } : f,
      );

    default:
      return state;
  }
}

interface BookingFilters {
  activeFilters: FilterParams;
  filters: FilterType[];
  isFilterLoading: boolean;
  period: DatesRangeValue;
  selected: FilterSelected[];
  setPeriod: Dispatch<SetStateAction<DatesRangeValue>>;
  filterDispatch: (_: FilterAction) => void;
}

export default function useBookingFilters(): BookingFilters {
  const { bookingRepository } = useRepository();
  const { facility, facilityId } = useFacility();

  const [activeFilters, setActiveFilters] = useState<FilterParams>({});
  const [filters, filterDispatch] = useReducer(filterReducer, initFilterState);
  const [period, setPeriod] = useState<DatesRangeValue>([null, null]);
  const [selected, setSelected] = useState<FilterSelected[]>([]);

  const { isLoading: isFilterLoading } = useQuery<unknown, void, FiltersApi>(
    'filters',
    () => bookingRepository.getFilters(facilityId, { agencyId: facility.agencyId }),
    {
      enabled: !!facility.agencyId && !filters.length,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const filterData: Record<string, DropdownOption[]> = {
          type_of_trip: tripType,
          transfer_type: transferType,
          ...(data?.passenger_agencies ? { passenger_agency: data?.passenger_agencies } : {}),
          ...(data?.booking_states ? { state: data?.booking_states } : {}),
        };

        filterDispatch({
          type: FilterActionTypes.INIT,
          payload: Object.keys(filterData).map((i) => ({ title: i, options: filterData[i] })),
        });
      },
      onError: () => {
        filterDispatch({
          type: FilterActionTypes.INIT,
          payload: filters.map((i) => ({ ...i, selected: undefined, options: [] })),
        });
      },
    },
  );

  useEffect(() => {
    setSelected(
      filters.filter((i) => i.selected).map((i) => ({ key: i.title, value: i.selected?.label! })),
    );
  }, [filters]);

  useEffect(() => {
    if (selected.length) {
      const activeFilterData: FilterParams = {};
      const mappedFilters = filters
        .filter((i) => i.selected)
        .map((i) => ({
          title: i.title,
          selected: i.options.find((j) => j.value === i.selected?.value)?.value,
        }));

      mappedFilters.forEach((i) => {
        activeFilterData[i.title] = i.selected!;
      });

      setActiveFilters(activeFilterData);
    }
  }, [filters, selected, setActiveFilters]);

  return {
    activeFilters,
    filterDispatch,
    filters,
    isFilterLoading,
    period,
    selected,
    setPeriod,
  };
}
