import { useTranslation } from 'react-i18next';
import type { UseFormReturn } from 'react-hook-form';
import type { IDropdownOption } from '@ui-modules/types';
import type { GoogleSuggestion } from '@components/form/types';
import { DateInputFormat } from '@common/types';
import { InputDatePicker, InputLocation, InputText, InputTimePicker, Select } from '@components';

type SectionProps = {
  city?: string;
  country?: string;
  form: UseFormReturn<any>;
  purpose: IDropdownOption[];
  showGoogleLocation?: boolean;
  showTown?: boolean;
};

const OneWaySection = ({
  city,
  country,
  form,
  purpose,
  showGoogleLocation,
  showTown,
}: SectionProps) => {
  const { t } = useTranslation();
  const { control: ctrl, setValue, watch } = form;
  const isDropoffLocExtraRequired = watch('routes.0.dropoffEqualFacility');
  const isPickupLocExtraRequired = watch('routes.0.pickupEqualFacility');

  const handleLocationChange = (name: string) => (e: GoogleSuggestion | string) => {
    if (typeof e === 'object') {
      setValue?.(`${name}EqualFacility`, e.dataset?.isFacilityLocation);
      setValue?.(`${name}Town`, e.dataset?.town ?? city);
      setValue?.(`${name}Lat`, e.dataset?.lat ?? 0);
      setValue?.(`${name}Lng`, e.dataset?.lng ?? 0);
    }
  };

  return (
    <fieldset data-testid="bf-booking-details-fieldset">
      {(showGoogleLocation || showTown) && (
        <>
          <InputText
            name="routes.0.pickupTown"
            className="field"
            control={ctrl}
            hidden={showGoogleLocation}
            label={t('bookingDetails.pickupTown')}
            required
            data-testid="bf-pickup-town"
          />
          <InputText
            name="routes.0.dropoffTown"
            className="field"
            control={ctrl}
            hidden={showGoogleLocation}
            label={t('bookingDetails.dropoffTown')}
            required
            data-testid="bf-dropoff-town"
          />
        </>
      )}

      <InputDatePicker
        name="routes.0.pickupDate"
        className="field"
        control={ctrl}
        label={t('bookingDetails.pickupDate')}
        minDate={new Date()}
        required
        valueFormat={DateInputFormat.DateByDots}
        data-testid="bf-pickup-date"
      />
      <InputTimePicker
        name="routes.0.pickupTime"
        className="field"
        control={ctrl}
        label={t('bookingDetails.pickupTime')}
        required
        data-testid="bf-pickup-time"
      />

      {showGoogleLocation ? (
        <>
          <InputLocation
            name="routes.0.pickupLocation"
            className="field"
            control={ctrl}
            defaultCity={city}
            defaultCountry={country}
            label={`${t('bookingDetails.pickupLocation')} (Google Maps)`}
            required
            onChange={handleLocationChange('routes.0.pickup')}
            data-testid="bf-pickup-location"
          />
          <InputLocation
            name="routes.0.dropoffLocation"
            className="field"
            control={ctrl}
            defaultCity={city}
            defaultCountry={country}
            label={`${t('bookingDetails.dropoffLocation')} (Google Maps)`}
            required
            onChange={handleLocationChange('routes.0.dropoff')}
            data-testid="bf-dropoff-location"
          />
          <InputText
            name="routes.0.pickupLocExtra"
            className="field"
            control={ctrl}
            label={`${t('bookingDetails.pickupLocExtra')} (Manual Input${
              isPickupLocExtraRequired ? '' : ', optional'
            })`}
            required={isPickupLocExtraRequired}
          />
          <InputText
            name="routes.0.dropoffLocExtra"
            className="field"
            control={ctrl}
            label={`${t('bookingDetails.dropoffLocExtra')} (Manual Input${
              isDropoffLocExtraRequired ? '' : ', optional'
            })`}
            required={isDropoffLocExtraRequired}
          />
        </>
      ) : (
        <>
          <InputText
            name="routes.0.pickupLocation"
            className="field"
            control={ctrl}
            label={t('bookingDetails.pickupLocation')}
            required
            data-testid="bf-pickup-location"
          />
          <InputText
            name="routes.0.dropoffLocation"
            className="field"
            control={ctrl}
            label={t('bookingDetails.dropoffLocation')}
            required
            data-testid="bf-dropoff-location"
          />
        </>
      )}

      <Select
        name="bookingExtra.purpose"
        className="field"
        control={ctrl}
        label={t('common.purpose')}
        options={purpose}
        required
        data-testid="bf-booking-purpose"
      />
      <InputText
        name="bookingExtra.remarks"
        className="field"
        control={ctrl}
        label={`${t('common.remarks')} (${t('common.optional')})`}
        data-testid="bf-booking-remarks"
      />
    </fieldset>
  );
};

export default OneWaySection;
