import { differenceInSeconds, format, intervalToDuration, parse } from 'date-fns';
import { DateFormat } from '@common/types';

export const addTimeToTime = (init: string, addTime: string): string => {
  const [hours1, minutes1] = init.split(':').map(Number);
  const [hours2, minutes2] = addTime.split(':').map(Number);

  let totalHours = hours1 + hours2;
  let totalMinutes = minutes1 + minutes2;

  totalHours += Math.floor(totalMinutes / 60);
  totalMinutes %= 60;

  const hours = totalHours.toString().padStart(2, '0');
  const minutes = totalMinutes.toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};

export const formatDateString = (date: string, formatFrom: string, formatTo: string): string =>
  format(parse(date, formatFrom, new Date()), formatTo);

export const formatStringToDate = (date: string, formatFrom: string): Date =>
  parse(date, formatFrom, new Date());

export const formatSecToHours = (seconds: number): string => {
  const { days, hours, minutes, months } = intervalToDuration({ start: 0, end: seconds * 1000 });

  let h = hours || 0;

  if (days) h += days * 24;
  if (months) h += months * 30 * 24;

  const formattedHours = h && h < 10 ? `0${h}` : h;
  const formattedMinutes = minutes !== undefined && minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes}`;
};

export const subtractTime = (start: string, end: string) => {
  const startDate = parse(start, DateFormat.ApiTimeFull, new Date());
  const endDate = parse(end, DateFormat.ApiTimeFull, new Date());
  const difference = differenceInSeconds(endDate, startDate);

  const hours = Math.floor(difference / 3600);
  const minutes = Math.floor((difference % 3600) / 60);
  const seconds = difference % 60;

  return format(new Date().setHours(hours, minutes, seconds), DateFormat.ApiTimeFull);
};
