import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import { TextInput, type TextInputProps } from '@mantine/core';

export type InputTextProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<TextInputProps, 'value' | 'defaultValue'>;

export function InputText<T extends FieldValues>({
  control,
  defaultValue,
  name,
  required,
  rules,
  shouldUnregister,
  onChange,
  ...props
}: InputTextProps<T>) {
  const {
    field: { value, onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
  });

  return (
    <TextInput
      classNames={{
        error: 'field-error',
        input: 'input-text',
        label: 'input-text-label',
        root: 'input-text-root',
        wrapper: 'input-text-wrapper',
      }}
      error={fieldState.error?.message}
      value={value}
      withAsterisk={!!required}
      onChange={(e) => {
        fieldOnChange(e);
        onChange?.(e);
      }}
      {...field}
      {...props}
    />
  );
}
