import { t } from 'i18next';
import { TextField } from '@ui-modules/informed';

const VehiclePlateNumberField = ({ disabled }: { disabled?: boolean }) => (
  <div className="field vehicle-plate-number">
    <TextField disabled={disabled} label={t('mobility.plateNumber')} max="10" name="plateNumber" />
  </div>
);

export default VehiclePlateNumberField;
