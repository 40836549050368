import { useState } from 'react';
import { useQuery } from 'react-query';

import { useAuth } from '@unbooking/ui-auth';
import { useRepository } from '@context';
import { IUserInfo, IUserInfoApi } from '@common/interfaces';
import { Serializer } from '@common/utils';

const useUserInfo = (): IUserInfo => {
  const { isAuthenticated } = useAuth();
  const { accountRepository } = useRepository();

  const [userInfo, setUserInfo] = useState<IUserInfo>({
    email: '',
    firstName: '',
    lastName: '',
  });

  useQuery('get-user', () => accountRepository.getUser(), {
    enabled: isAuthenticated,
    onSuccess: (data: IUserInfoApi) => {
      if (data) setUserInfo(Serializer.formatUser(data));
    },
  });

  return userInfo;
};

export default useUserInfo;
