import type { IDropdownOption } from '@ui-modules/types';

export const getLocationChoices = (data: {
  [key: string]: string[];
}): { [key: string]: IDropdownOption[] } =>
  Object.fromEntries(
    Object.entries(data).map(([key, values]) => [
      key,
      values.map((value) => ({ label: value, value })),
    ]),
  ) ?? {};
