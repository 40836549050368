import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import { type AutocompleteProps, Autocomplete, Modal, Tooltip } from '@mantine/core';
import { Loader } from '@googlemaps/js-api-loader';
import { useDebounce } from 'usehooks-ts';

import { Button, GoogleMaps } from '@components';
import { CloseRound, Process } from '@assets/svg/icons';
import { GoogleSuggestion } from '../types';

const G_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const G_CITY = ['locality', 'administrative_area_level_1', 'administrative_area_level_2'];
const G_ROUTE = ['route', 'sublocality', 'sublocality_level_1', 'administrative_area_level_2'];

type AddressComponent = google.maps.GeocoderAddressComponent;
type LatLngLiteral = google.maps.LatLngLiteral;

const sortByPriority = (a: AddressComponent, b: AddressComponent, priorities: string[]) =>
  priorities.findIndex((type) => a.types.includes(type)) -
  priorities.findIndex((type) => b.types.includes(type));

export type InputLocationProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<AutocompleteProps, 'data' | 'value' | 'onChange'> & {
    debuggingGoogleMap?: boolean;
    defaultCity?: string;
    defaultCountry?: string;
    onChange?: (e: GoogleSuggestion | string) => void;
  };

export function InputLocation<T extends FieldValues>({
  control,
  defaultCity,
  defaultCountry,
  debuggingGoogleMap,
  label,
  name,
  required,
  rules,
  shouldUnregister,
  onChange,
  ...props
}: InputLocationProps<T>) {
  const { i18n, t } = useTranslation();
  const { language } = i18n;
  const {
    field: { value, onBlur, onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    control,
    name,
    rules,
    shouldUnregister,
  });

  const [isInputLocked, setInputLocked] = useState<boolean>(false);
  const [isMapOpen, setMapOpen] = useState<boolean>(false);
  const [locationQuery, setLocationQuery] = useState<string>();
  const [selectedLocation, setSelectedLocation] = useState<LatLngLiteral | null>();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<GoogleSuggestion[]>([]);

  const [countryName, isoCode] = defaultCountry?.match(/(.*)\s\[(.*)\]/)?.slice(1) ?? [];
  const debouncedQuery = useDebounce(locationQuery, 800);

  const getFallbackOption = useCallback(
    (cords: LatLngLiteral) => ({
      value: `${defaultCity}, ${countryName}`,
      dataset: {
        isFacilityLocation: true,
        location: `${defaultCity}, ${countryName}`,
        town: defaultCity,
        ...cords,
      },
    }),
    [countryName, defaultCity],
  );

  const mapResponseToSuggestion = (result: google.maps.places.PlaceResult) => {
    const location = result.name
      ? `${result.name} - ${result.formatted_address}`
      : result.formatted_address!;
    const town =
      (result.plus_code?.compound_code?.split(' ')[1] ?? '').replace(/,$/, '') ||
      result.formatted_address;

    return {
      value: location,
      dataset: {
        lat: result.geometry?.location?.lat() ?? 0,
        lng: result.geometry?.location?.lng() ?? 0,
        location,
        town,
      },
    };
  };

  const processPlaceDetails = useCallback(
    async (place: google.maps.places.PlaceResult, coords: LatLngLiteral) => {
      const address = place.address_components || [];
      const hashPattern = /\b\w*\+\w*/;
      const placeName = place.name?.replace(hashPattern, '').trim();

      address.sort((a, b) => sortByPriority(a, b, G_CITY));
      const town =
        address.find((i) => G_CITY.some((j) => i.types.includes(j)))?.long_name ||
        place.formatted_address;

      address.sort((a, b) => sortByPriority(a, b, G_ROUTE));
      const route = address.find((i) => G_ROUTE.some((j) => i.types.includes(j)))?.short_name;

      let location = placeName
        ? `${placeName}${route ? ` - ${route}` : ''}, ${town}`
        : `${route ? `${route}, ` : ''}${town}`;

      const hashMatchInName = place.name?.match(hashPattern);
      const hashMatchInAddress = place.formatted_address?.match(hashPattern);

      if (hashMatchInAddress) {
        const hash = hashMatchInAddress[0];
        location = location.replace(hash, '').trim();
        location = `${location}, ${hash}`;

        const formattedAddressWords = place.formatted_address?.split(' ') || [];
        const isFacilityLocation =
          hashMatchInAddress && hashMatchInName && formattedAddressWords.length <= 3;

        if (place.formatted_address === hash) {
          location = `${defaultCity}, ${countryName}, ${hash}`;
        }

        return {
          value: location,
          dataset: {
            ...coords,
            location,
            town,
            ...(isFacilityLocation ? { isFacilityLocation: true } : {}),
          },
        };
      }

      return {
        value: location,
        dataset: { ...coords, location, town },
      };
    },
    [countryName, defaultCity],
  );

  const fetchSuggestions = useCallback(
    async (input: string) => {
      const loader = new Loader({ apiKey: G_API_KEY ?? '', version: 'weekly' });
      const { PlacesService } = await loader.importLibrary('places');
      const placesService = new PlacesService(document.createElement('div'));

      const res = await new Promise<google.maps.places.PlaceResult[]>((resolve, reject) => {
        placesService.textSearch({ query: input, language, region: isoCode }, (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && results) {
            resolve(results);
          } else {
            reject(new Error('Failed to fetch text search results'));
          }
        });
      });

      setSuggestions([
        { value: 'Select the location on Google map' },
        ...(res.map(mapResponseToSuggestion) ?? []),
      ]);
    },
    [isoCode, language],
  );

  useEffect(() => {
    if (debouncedQuery) fetchSuggestions(debouncedQuery);
  }, [fetchSuggestions, debouncedQuery]);

  const fetchPlaceDetails = useCallback(
    async (coords: LatLngLiteral, placeId: string) => {
      const loader = new Loader({ apiKey: G_API_KEY ?? '', version: 'weekly' });
      const { PlacesService } = await loader.importLibrary('places');
      const placesService = new PlacesService(document.createElement('div'));
      const placeResult = await new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
        placesService.getDetails({ placeId, language }, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && place) {
            resolve(place);
          } else {
            reject(new Error('Failed to fetch place details'));
          }
        });
      });

      const details = await processPlaceDetails(placeResult, coords);
      fieldOnChange(details.value);
      onChange?.(details);

      if (debuggingGoogleMap) {
        console.log('google place details', placeResult); // TODO: temporary log for testing
        console.log('payload', details); // TODO: temporary log for testing
      }
    },
    [debuggingGoogleMap, fieldOnChange, language, onChange, processPlaceDetails],
  );

  const fetchLocation = useCallback(
    async (coords: LatLngLiteral) => {
      try {
        const loader = new Loader({ apiKey: G_API_KEY ?? '', version: 'weekly' });
        await loader.importLibrary('geocoding');
        const geocoder = new google.maps.Geocoder();

        const res = await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
          geocoder.geocode({ language, location: coords }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK && results) {
              resolve(results);
            } else {
              reject(new Error('Failed to fetch location details'));
            }
          });
        });

        if (res.length) {
          fetchPlaceDetails(coords, res[0]?.place_id);
        } else {
          const payload = getFallbackOption(coords);
          fieldOnChange(payload.value);
          onChange?.(payload);
        }
      } catch (error) {
        const payload = getFallbackOption(coords);
        fieldOnChange(payload.value);
        onChange?.(payload);
      }
    },
    [fetchPlaceDetails, fieldOnChange, getFallbackOption, language, onChange],
  );

  const onClose = () => {
    setMapOpen(false);
  };

  const onInputClear = () => {
    setInputLocked(false);
    setShowTooltip(false);
    setSelectedLocation(null);
    setSuggestions([]);
    setLocationQuery('');
    fieldOnChange('');
  };

  const onMapOpen = () => {
    setMapOpen(true);
  };

  const onSelectLocation = (coords: LatLngLiteral) => {
    setSelectedLocation(coords);
    setInputLocked(true);
  };

  const onSubmit = () => {
    if (selectedLocation) fetchLocation(selectedLocation);
    setMapOpen(false);
  };

  const handleAutocompleteChange = async (input: GoogleSuggestion) => {
    const selectedSuggestion = suggestions.find((s) => s.value === input.value);
    if (selectedSuggestion) {
      if (selectedSuggestion.value === 'Select the location on Google map') {
        onInputClear();
        onMapOpen();
      } else {
        setInputLocked(true);
        fieldOnChange(selectedSuggestion.value);
        onChange?.(selectedSuggestion);
      }
    }
  };

  const handleInputChange = (input: string) => {
    setLocationQuery(input);
    fieldOnChange(input);
    setShowTooltip(!!input);
  };

  const handleInputFocus = () => {
    setShowTooltip(true);
  };

  const handleInputBlur = () => {
    setShowTooltip(false);
  };

  return (
    <>
      <Autocomplete
        classNames={{
          error: 'field-error',
          input: 'input-text',
          label: 'input-text-label',
          wrapper: 'input-text-wrapper',
        }}
        data={suggestions}
        error={fieldState.error?.message}
        filter={() => true}
        label={
          <Tooltip
            label={
              <span>
                Specify desired location <b>as accurately as possible</b>. If Google returns no
                results, then fill in your exact address in the field below
              </span>
            }
            arrowSize={6}
            color="blue"
            multiline
            opened={showTooltip}
            withArrow
            width={280}
          >
            <span>{label}</span>
          </Tooltip>
        }
        limit={10}
        readOnly={isInputLocked}
        rightSection={
          isInputLocked ? (
            <Button leftIcon={<CloseRound />} variant="icon" onClick={onInputClear} />
          ) : (
            <Button leftIcon={<Process />} variant="icon" onClick={onMapOpen} />
          )
        }
        value={value}
        withAsterisk={!!required}
        onBlur={handleInputBlur}
        onChange={(e) => handleInputChange(e)}
        onFocus={handleInputFocus}
        onItemSubmit={handleAutocompleteChange}
        {...field}
        {...props}
      />

      <Modal
        title={label}
        classNames={{
          content: 'modal-recap-booking',
          header: 'modal-recap-booking-header',
          body: 'modal-recap-booking-body',
        }}
        opened={isMapOpen}
        onClose={onClose}
        size={900}
      >
        <GoogleMaps defaultCity={defaultCity} onSelectLocation={onSelectLocation} />

        <footer>
          <Button text={t('common.btnCancel')} variant="warning" onClick={onClose} />
          <Button text={t('common.btnConfirm')} variant="submit" onClick={onSubmit} />
        </footer>
      </Modal>
    </>
  );
}
