/* istanbul ignore file */
/* eslint-disable no-plusplus */
import {
  areIntervalsOverlapping,
  compareAsc,
  differenceInMinutes,
  format,
  isEqual,
  parse,
  setMinutes,
  setSeconds,
  startOfMinute,
} from 'date-fns';
import { IDropdownOption } from '@ui-modules/types';
import { TIME_INTERVAL } from '@common/constants';
import { DriveCreate, FormDrive, PlAcceptDrive, PlDrive, TransferType } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';

export type VerifiedTransferType = {
  scheduledRouteId: string | null;
  transferType: TransferType;
  typeChanged?: boolean;
};

export const getFullDate = (date: Date, time: Date): Date =>
  parse(
    `${format(date, DF.ApiDate)} ${format(time, DF.ApiTime)}`,
    `${DF.ApiDate} ${DF.ApiTime}`,
    new Date(),
  );

export const getSoonTime = (date: Date): Date =>
  setSeconds(
    setMinutes(startOfMinute(date), Math.round(date.getMinutes() / TIME_INTERVAL) * TIME_INTERVAL),
    0,
  );

export const calcSharedDrive = ({
  driver,
  drives,
  dropoffDate,
  dropoffTime,
  pickupDate,
  pickupTime,
  vehicle,
}: {
  driver?: IDropdownOption;
  drives: PlDrive[];
  dropoffDate?: Date;
  dropoffTime?: Date;
  pickupDate?: Date;
  pickupTime?: Date;
  vehicle: IDropdownOption;
}): PlDrive | null => {
  /* We get start dates, end dates and vehicle so we should calculate do we have some drive
   * in these dates with this vehicle. Filtrate the array of all drives and:
   * - return null, if we have no results
   * - return first result if we have some results
   * We haven't logic for multi-results for now, so just use first result
   */
  if (!dropoffDate || !dropoffTime || !pickupDate || !pickupTime) return null;

  const sharedDrive = drives
    .filter((d: PlDrive) => {
      if (!driver) return d.vehicleId === vehicle?.value;
      return d.vehicleId === vehicle?.value || d.driverId === driver?.value;
    })
    .filter((d: PlDrive) => {
      const existStart = parse(`${d.pickupDate} ${d.pickupTime}`, DF.ApiDateAltFull, new Date());
      const existEnd = parse(`${d.dropoffDate} ${d.dropoffTime}`, DF.ApiDateAltFull, new Date());
      const selectedDriveStart = getFullDate(pickupDate, pickupTime);
      const selectedDriveEnd = getFullDate(dropoffDate, dropoffTime);

      return (
        !isEqual(existStart, selectedDriveEnd) &&
        !isEqual(existEnd, selectedDriveStart) &&
        differenceInMinutes(selectedDriveEnd, existStart) > 0 &&
        differenceInMinutes(existEnd, selectedDriveStart) > 0
      );
    });

  if (sharedDrive.length !== 0) return sharedDrive[0];
  return null;
};

export const checkConflictDrives = ({
  driver,
  drives,
  dropoffDate,
  dropoffTime,
  pickupDate,
  pickupTime,
  transferType,
  vehicle,
}: {
  driver: IDropdownOption;
  drives: PlDrive[];
  dropoffDate: Date;
  dropoffTime: Date;
  pickupDate: Date;
  pickupTime: Date;
  transferType?: TransferType;
  vehicle: IDropdownOption;
}): 'driver' | 'vehicle' | null => {
  let conflict = null;

  const relevantDrives = drives.filter(
    (d) => d.vehicleId === vehicle.value || d.driverId === driver.value,
  );

  const selectedStart = getFullDate(pickupDate, pickupTime);
  const selectedEnd = getFullDate(dropoffDate, dropoffTime);

  relevantDrives.forEach((drive: PlDrive) => {
    if (transferType === TransferType.Shuttle && drive.scheduledRouteId !== null) return;

    const start = parse(`${drive.pickupDate} ${drive.pickupTime}`, DF.ApiDateAltFull, new Date());
    const end = parse(`${drive.dropoffDate} ${drive.dropoffTime}`, DF.ApiDateAltFull, new Date());

    if (
      areIntervalsOverlapping({ start: selectedStart, end: selectedEnd }, { start, end }) &&
      !(drive.vehicleId === vehicle.value && drive.driverId === driver.value)
    ) {
      conflict = drive.vehicleId === vehicle.value ? 'vehicle' : 'driver';
    }
  });

  return conflict;
};

export const checkConflictDriveOnEdit = (drives: FormDrive[]): boolean => {
  if (drives.length <= 1) return false;

  const overlappingDrives = drives.map((drive: FormDrive, idx: number) => {
    const comparedStart = getFullDate(drive.pickupDate, drive.pickupTime);
    const comparedEnd = getFullDate(drive.dropoffDate, drive.dropoffTime);

    return drives
      .filter((_, i: number) => i !== idx)
      .map((d: FormDrive) => {
        const start = getFullDate(d.pickupDate, d.pickupTime);
        const end = getFullDate(d.dropoffDate, d.dropoffTime);

        if (compareAsc(comparedEnd, start) <= 0) return false;

        return areIntervalsOverlapping({ start: comparedStart, end: comparedEnd }, { start, end });
      });
  });

  return overlappingDrives.flat().includes(true);
};

export const checkDriveIsRideShared = ({
  data,
  drives,
}: {
  data: PlAcceptDrive;
  drives: PlDrive[];
}): boolean => {
  const { driverId, dropoffDate, dropoffTime, pickupDate, pickupTime, vehicleId } = data;
  const relevantDrives = drives.filter((d) => d.vehicleId === vehicleId || d.driverId === driverId);

  const acceptRange = {
    start: parse(`${pickupDate} ${pickupTime}`, `${DF.ApiDate} ${DF.ApiTime}`, new Date()),
    end: parse(`${dropoffDate} ${dropoffTime}`, `${DF.ApiDate} ${DF.ApiTime}`, new Date()),
  };

  const rideShared = relevantDrives.map((d: PlDrive) => {
    const driveRange = {
      start: parse(`${d.pickupDate} ${d.pickupTime}`, DF.ApiDateAltFull, new Date()),
      end: parse(`${d.dropoffDate} ${d.dropoffTime}`, DF.ApiDateAltFull, new Date()),
    };

    return (
      areIntervalsOverlapping(acceptRange, driveRange) &&
      d.vehicleId === vehicleId &&
      d.driverId === driverId
    );
  });

  return rideShared.includes(true);
};

export const checkOverlap = (firstDrive: DriveCreate, secondDrive: DriveCreate): boolean =>
  firstDrive.driver.value !== secondDrive.driver.value ||
  firstDrive.vehicle.value !== secondDrive.vehicle.value ||
  areIntervalsOverlapping(
    {
      start: getFullDate(firstDrive.pickupDate, firstDrive.pickupTime),
      end: getFullDate(firstDrive.dropoffDate, firstDrive.dropoffTime),
    },
    {
      start: getFullDate(secondDrive.pickupDate, secondDrive.pickupTime),
      end: getFullDate(secondDrive.dropoffDate, secondDrive.dropoffTime),
    },
  );

export const checkOverlappingDrives = (drives: DriveCreate[]): boolean => {
  if (drives?.length <= 1) {
    return false;
  }

  const checkedIndexes: string[] = [];
  for (let i = 0; i <= drives.length; i++) {
    for (let j = i + 1; j < drives.length; j++) {
      if (!checkedIndexes.includes(`${i}${j}`)) {
        const isOverlapping = checkOverlap(drives[i], drives[j]);

        if (isOverlapping) {
          return true;
        }

        checkedIndexes.push(`${i}${j}`);
      }
    }
  }

  return false;
};

export const checkShuttleConflictRange = ({
  driver,
  drives,
  end,
  start,
  vehicle,
}: {
  driver: string;
  drives: PlDrive[];
  end: Date;
  start: Date;
  vehicle: string;
}): 'driver' | 'time' | null => {
  const scheduled = drives.find((d) => {
    const driveRange = {
      start: parse(`${d.pickupDate} ${d.pickupTime}`, DF.ApiDateAltFull, new Date()),
      end: parse(`${d.dropoffDate} ${d.dropoffTime}`, DF.ApiDateAltFull, new Date()),
    };

    return (
      d.vehicleId === vehicle &&
      d.scheduledRouteId !== null &&
      areIntervalsOverlapping({ start, end }, driveRange)
    );
  });

  if (scheduled) {
    const scheduledStart = parse(
      `${scheduled.pickupDate} ${scheduled.pickupTime}`,
      DF.ApiDateAltFull,
      new Date(),
    );
    const scheduledEnd = parse(
      `${scheduled.dropoffDate} ${scheduled.dropoffTime}`,
      DF.ApiDateAltFull,
      new Date(),
    );

    if (scheduled.driverId !== driver) return 'driver';
    if (start < scheduledStart || end > scheduledEnd) return 'time';
  }

  return null;
};

export const checkShuttleType = ({
  drives,
  end,
  start,
  transferType,
  vehicle,
}: {
  drives: PlDrive[];
  end: Date;
  start: Date;
  transferType: TransferType;
  vehicle: string;
}): VerifiedTransferType | null => {
  const hasShuttleOverlap = drives.find((d) => {
    const driveRange = {
      start: parse(`${d.pickupDate} ${d.pickupTime}`, DF.ApiDateAltFull, new Date()),
      end: parse(`${d.dropoffDate} ${d.dropoffTime}`, DF.ApiDateAltFull, new Date()),
    };

    return (
      d.scheduledRouteId &&
      d.vehicleId === vehicle &&
      areIntervalsOverlapping({ start, end }, driveRange)
    );
  });

  if (!hasShuttleOverlap && transferType === TransferType.Shuttle) {
    return { scheduledRouteId: null, transferType: TransferType.InTown };
  }
  if (hasShuttleOverlap) {
    return {
      scheduledRouteId: hasShuttleOverlap.scheduledRouteId || null,
      transferType: TransferType.Shuttle,
      typeChanged: transferType !== TransferType.Shuttle,
    };
  }

  return null;
};

type Validator = (value: unknown) => string | void;
type ValidatorExt = (value: unknown, values: Record<string, unknown>) => unknown | void;
export const validateWithPax =
  (validator: Validator): ValidatorExt =>
  (value: unknown, { mainInfo }: Record<string, any>) => {
    if (!mainInfo?.noPax) return validator(value);
  };
