import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@unbooking/ui-modules';
import { BookingRoute, BookingState } from '@common/interfaces';

type DrivesTableProps = { data: BookingRoute[]; state: BookingState };

export const DrivesTable = ({ data, state }: DrivesTableProps) => {
  const { t } = useTranslation();

  const columns = [
    {
      key: '',
      render: () => <div />,
    },
    {
      key: 'pickup',
      title: t('bookingDetails.pickupDate'),
      render: (r: BookingRoute) => (
        <div>
          {r.pickupDate}
          <div className="sub">{r.pickupTime}</div>
        </div>
      ),
    },
    {
      key: 'pickupLocation',
      title: t('bookingDetails.pickupLocation'),
      ellipsis: {
        showTitle: true,
      },
      render: (r: BookingRoute) => (
        <div>
          {r.pickupLocation}
          <div className="sub">{r.pickupTown}</div>
        </div>
      ),
    },
    {
      key: 'dropoffDate',
      title: t('bookingDetails.dropoffDate'),
      render: (r: BookingRoute) => (
        <div>
          {r.dropoffDate}
          {state !== BookingState.NotProcessed && <div className="sub">{r.dropoffTime}</div>}
        </div>
      ),
    },
    {
      key: 'dropoffLocation',
      title: t('bookingDetails.dropoffLocation'),
      ellipsis: {
        showTitle: true,
      },
      render: (r: BookingRoute) => (
        <div>
          {r.dropoffLocation}
          <div className="sub">{r.dropoffTown}</div>
        </div>
      ),
    },
    {
      dataIndex: 'driver',
      key: 'driver',
      title: t('common.driver'),
    },
    {
      dataIndex: 'vehicle',
      key: 'vehicle',
      title: t('mobility.plateNumber'),
    },
    {
      dataIndex: 'stateDisplay',
      key: 'stateDisplay',
      title: t('common.status'),
    },
  ];

  return (
    <div className="table-drives">
      <Table columns={columns} data={data} rowKey="bookedRouteOrder" variant="dark" />
    </div>
  );
};

export default memo(DrivesTable);
