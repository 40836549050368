import {
  DriverCreate,
  DriverUpdate,
  IMobilityRepository,
  MobilityParams,
  VehicleCreate,
  VehicleUpdate,
  UnavailabilityUpdate,
} from '@common/interfaces';
import AxiosRepository from './axios.repository';

class MobilityRepository extends AxiosRepository implements IMobilityRepository {
  private buildParams(params?: MobilityParams) {
    const searchParams = new URLSearchParams();

    if (params?.agencyId) searchParams.append('agency', params.agencyId);
    if (params?.limit) searchParams.append('limit', String(params.limit));
    if (params?.offset) searchParams.append('offset', String(params.offset));
    if (params?.ordering) searchParams.append('ordering', params.ordering);

    return searchParams;
  }

  async createDriver(facility: string, payload: DriverCreate) {
    const { data } = await this.post(`/facilities/${facility}/mobility/drivers/create/`, payload);

    return data;
  }

  async createVehicle(facility: string, payload: VehicleCreate) {
    const { data } = await this.post(`/facilities/${facility}/mobility/vehicles/create/`, payload);

    return data;
  }

  async deleteUnavailabilities(
    facility: string,
    record: string,
    unavailability: string,
    type: 'driver' | 'vehicle',
  ) {
    const typeString = type === 'driver' ? 'drivers' : 'vehicles';
    const { data } = await this.delete(
      `/facilities/${facility}/mobility/${typeString}/${record}/unavailabilities/${unavailability}/`,
    );
    return data;
  }

  async getDriverDetails(facility: string, driverId: string, params?: MobilityParams) {
    const { data } = await this.get(`/facilities/${facility}/mobility/drivers/${driverId}/`, {
      params: this.buildParams(params),
    });

    return data;
  }

  async getDrivers(facility: string, params?: MobilityParams, shared?: boolean) {
    const query = this.buildParams(params);
    const { data } = await this.get(
      `/facilities/${facility}/mobility/drivers/${shared ? 'shared/' : ''}?${query}`,
    );

    return data;
  }

  async getVehicleDetails(facility: string, vehicleId: string, params?: MobilityParams) {
    const { data } = await this.get(`/facilities/${facility}/mobility/vehicles/${vehicleId}/`, {
      params: this.buildParams(params),
    });

    return data;
  }

  async getVehicleModels(facility: string, params?: MobilityParams) {
    const query = this.buildParams(params);
    const { data } = await this.get(`/facilities/${facility}/mobility/vehicles/models/?${query}`);

    return data;
  }

  async getVehicles(facility: string, params?: MobilityParams, sharedVehicles?: boolean) {
    const query = this.buildParams(params);
    const { data } = await this.get(
      `/facilities/${facility}/mobility/vehicles/${sharedVehicles ? 'shared/' : ''}?${query}`,
    );
    return data;
  }

  async updateDriver(facility: string, driverId: string, payload: DriverUpdate) {
    const { data } = await this.patch(
      `/facilities/${facility}/mobility/drivers/${driverId}/`,
      payload,
    );
    return data;
  }

  async updateVehicle(facility: string, vehicleId: string, payload: VehicleUpdate) {
    const { data } = await this.patch(
      `/facilities/${facility}/mobility/vehicles/${vehicleId}/`,
      payload,
    );
    return data;
  }

  async updateUnavailabilities(
    facility: string,
    record: string,
    type: 'driver' | 'vehicle',
    payload: UnavailabilityUpdate,
  ) {
    const typeString = type === 'driver' ? 'drivers' : 'vehicles';
    const { data } = await this.post(
      `/facilities/${facility}/mobility/${typeString}/${record}/unavailabilities/`,
      payload,
    );
    return data;
  }

  async vehicleConnectGPS(facility: string, vehicleId: string, connected: boolean) {
    const url = `/facilities/${facility}/mobility/vehicles/${vehicleId}/connect-gps/`;
    const { data } = await (connected ? this.delete(url) : this.post(url));
    return data;
  }
}

export default MobilityRepository;
