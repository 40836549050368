import { z } from 'zod';
import { emailValidator } from '@common/utils';

export enum BookingFormType {
  Self = 'self',
  Colleague = 'colleague',
}

export enum BookingType {
  Airport = 'airport',
  InTown = 'in_town',
  OutTown = 'out_of_town',
  Shuttle = 'shuttle',
}

const fieldRequired = { message: 'This field is required', type: 'required' };

export const bookingFormSchema = z
  .object({
    acceptConditions: z.boolean(),
    addRecurring: z.boolean(),
    agentDetails: z
      .object({
        email: z.string().email(),
        firstName: z.string().max(255),
        lastName: z.string().max(255),
      })
      .optional(),
    bookingDetails: z.object({
      agency: z.string().min(1, fieldRequired),
      attachments: z.array(z.instanceof(File)).optional(),
      email: z.string().email().min(1, fieldRequired),
      firstName: z.string().max(255).min(1, fieldRequired),
      indexNumber: z.string().max(255).optional(),
      lastName: z.string().max(255).min(1, fieldRequired),
      phoneNumber: z.string().max(255).optional(),
    }),
    bookingExtra: z.object({
      flightArrivalDepartureTime: z.string().optional(),
      flightNumber: z.string().optional(),
      managerEmail: z.string().optional(),
      pax: z.string().optional(),
      purpose: z.string().optional(),
      requestingUnit: z.string().optional(),
      remarks: z.string().optional(),
    }),
    bookingFormType: z.string().min(1, fieldRequired),
    passengersInfo: z.array(
      z.object({
        agency: z.string().max(255).min(1, fieldRequired),
        email: z.string().email().min(1, fieldRequired),
        firstName: z.string().max(255).min(1, fieldRequired),
        lastName: z.string().max(255).min(1, fieldRequired),
        phoneNumber: z.string().max(255).min(1, fieldRequired),
        requestingUnit: z.string().min(1, fieldRequired),
      }),
    ),
    recurring: z.object({
      recurringFrequency: z.string().optional(),
      recurringPeriod: z.string().optional(),
      recurringUntil: z.date().nullable().optional(),
    }),
    routes: z.array(
      z
        .object({
          dropoffEqualFacility: z.boolean().optional(),
          dropoffLat: z.number().optional(),
          dropoffLng: z.number().optional(),
          dropoffLocation: z.string().max(255).min(1, fieldRequired),
          dropoffLocExtra: z.string().optional(),
          dropoffTime: z.string().optional(),
          dropoffTown: z.string().max(255).min(1, fieldRequired),
          pickupDate: z
            .date()
            .nullable()
            .refine((v) => v !== null, fieldRequired),
          pickupEqualFacility: z.boolean().optional(),
          pickupLat: z.number().optional(),
          pickupLng: z.number().optional(),
          pickupLocation: z.string().max(255).min(1, fieldRequired),
          pickupLocExtra: z.string().optional(),
          pickupTime: z
            .string()
            .min(1, fieldRequired)
            .refine((v) => v !== null, fieldRequired),
          pickupTown: z.string().max(255).min(1, fieldRequired),
          scheduledRouteId: z.string().optional(),
        })
        .refine((data) => !data.dropoffEqualFacility || !!data.dropoffLocExtra, {
          message: fieldRequired.message,
          path: ['dropoffLocExtra'],
        })
        .refine((data) => !data.pickupEqualFacility || !!data.pickupLocExtra, {
          message: fieldRequired.message,
          path: ['pickupLocExtra'],
        }),
    ),
    transferType: z.string(),
    typeOfTrip: z.string(),
    travellerDetails: z
      .object({
        agency: z.string(),
        email: z.string().max(255),
        firstName: z.string().max(255),
        indexNumber: z.string().optional(),
        lastName: z.string().max(255),
        phoneNumber: z.string().max(255),
        requestingUnit: z.string(),
      })
      .nullable()
      .optional(),
  })
  .refine(
    (v) =>
      v.transferType !== 'airport' || v.bookingExtra?.flightArrivalDepartureTime?.trim() !== '',
    {
      message: fieldRequired.message,
      path: ['bookingExtra', 'flightArrivalDepartureTime'],
    },
  )
  .refine((v) => v.transferType !== 'airport' || v.bookingExtra?.flightNumber?.trim() !== '', {
    message: fieldRequired.message,
    path: ['bookingExtra', 'flightNumber'],
  })
  .refine((v) => v.transferType === 'airport' || v.bookingExtra?.purpose?.trim() !== '', {
    message: fieldRequired.message,
    path: ['bookingExtra', 'purpose'],
  })
  .refine((v) => v.transferType === 'airport' || v.typeOfTrip?.trim() !== '', {
    message: fieldRequired.message,
    path: ['typeOfTrip'],
  })
  .refine(
    (v) => v.bookingFormType === BookingFormType.Colleague || v.bookingExtra?.pax?.trim() !== '',
    {
      message: fieldRequired.message,
      path: ['bookingExtra', 'pax'],
    },
  )
  .refine(
    (v) =>
      v.bookingFormType === BookingFormType.Colleague ||
      v.bookingExtra?.requestingUnit?.trim() !== '',
    {
      message: fieldRequired.message,
      path: ['bookingExtra', 'requestingUnit'],
    },
  )
  .refine(
    (v) =>
      v.bookingFormType === BookingFormType.Colleague ||
      v.bookingDetails?.phoneNumber?.trim() !== '',
    {
      message: fieldRequired.message,
      path: ['bookingDetails', 'phoneNumber'],
    },
  )
  .superRefine((data, ctx) => {
    if (data.bookingFormType === BookingFormType.Colleague) {
      if (
        data.travellerDetails?.email &&
        typeof emailValidator(data.travellerDetails?.email) === 'string'
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Email should be correct',
          path: ['travellerDetails', 'email'],
        });
      }

      Object.keys(data.travellerDetails || {}).forEach((field) => {
        if (field === 'indexNumber') return;

        if (!data.travellerDetails?.[field as keyof FormSchemaType['travellerDetails']]) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: fieldRequired.message,
            path: ['travellerDetails', field],
          });
        }
      });
    }

    if (data.addRecurring) {
      const { recurring } = data;
      Object.keys(recurring || {}).forEach((field) => {
        if (!recurring?.[field as keyof FormSchemaType['recurring']]) {
          if (field === 'recurringFrequency' && Number(recurring?.recurringPeriod!) === 2) return;
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: fieldRequired.message,
            path: ['recurring', field],
          });
        }
      });
    }
  });

export type FormSchemaType = z.infer<typeof bookingFormSchema>;
