import { type FC, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { Form, FormState } from 'informed';

import { Button } from '@unbooking/ui-modules';
import { IDropdownOption } from '@ui-modules/types';
import { useRepository } from '@context';
import { useDrivers, useFacility } from '@common/hooks';
import { Serializer, createPaxOptions } from '@common/utils';
import {
  ApiList,
  VehicleCreate,
  VehicleDetails,
  VehicleDetailsApi,
  VehicleModelListApi,
  VehicleUpdate,
  VehicleOwner,
  VehicleProtection,
} from '@common/interfaces';
import { Loader } from '@components';
import { CheckMark, GPSConnect, VehiclePlaceholder } from '@assets/svg/icons';
import {
  PreferredDriverField,
  VehicleAgencyField,
  VehicleChassisNumberField,
  VehicleGvlpNumberField,
  VehicleModelField,
  VehicleOwnerField,
  VehiclePaxField,
  VehiclePlateNumberField,
  VehicleProtectionField,
  VehicleRoofNumberField,
  VehicleSharedAgencyField,
} from '../fields';
import './styles.scss';

export interface FormStateProps {
  agency: string;
  chassisNumber: string;
  city: string;
  country: string;
  gvlpNumber: string;
  maxCapacity: IDropdownOption;
  plateNumber: string;
  preferredDriver: IDropdownOption;
  protection: IDropdownOption;
  roofNumber: string;
  sharedLocations: IDropdownOption[];
  vehicleModel: IDropdownOption;
  vehicleOwner: IDropdownOption;
}

export interface VehicleFormProps {
  isLoading: boolean;
  readOnly?: boolean;
  vehicleId: string | null;
  createVehicle: (data: VehicleCreate) => void;
  updateVehicle: ({ vehicleId, data }: { vehicleId: string; data: VehicleUpdate }) => void;
}

const VehicleEditForm: FC<VehicleFormProps> = ({
  isLoading,
  readOnly = false,
  vehicleId,
  createVehicle,
  updateVehicle,
}) => {
  const { t } = useTranslation();
  const { driverOptions } = useDrivers();
  const { mobilityRepository } = useRepository();
  const { agencyName, facility, facilityId: fid } = useFacility();
  const { city, country } = facility;
  const isEditProcess = !!vehicleId;

  const [details, setDetails] = useState<VehicleDetails>();
  const [gpsError, setGpsError] = useState<string | null>(null);
  const [isGPSConnected, setIsGPSConnected] = useState<boolean>(false);
  const [vehicleModelOptions, setVehicleModelOptions] = useState<IDropdownOption[]>();

  const { isFetching: isVehicleDetailsLoading } = useQuery(
    'get-vehicle-details',
    () => mobilityRepository.getVehicleDetails(fid, vehicleId!, { agencyId: facility?.agencyId }),
    {
      enabled: isEditProcess,
      onSuccess: (data: VehicleDetailsApi) => {
        setDetails(Serializer.formatVehicleDetails(data));
        setIsGPSConnected(data.connected_to_gps);
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          toast.error(error.message || t('common.errorMsgDefault'));
        }
      },
    },
  );

  useQuery('get-vehicle-models', () => mobilityRepository.getVehicleModels(fid, { limit: 250 }), {
    enabled: !isEditProcess,
    onSuccess: (data: ApiList<VehicleModelListApi>) => {
      setVehicleModelOptions(data.results?.map(Serializer.formatVehicleModels));
    },
  });

  const { mutate: onGPSConnection, isLoading: isGPSLoading } = useMutation<unknown, AxiosError>(
    'connect-gps',
    () => mobilityRepository.vehicleConnectGPS(fid, details?.id!, details?.connectedToGps!),
    {
      onSuccess: () => {
        setIsGPSConnected(!isGPSConnected);
        setGpsError(null);
      },
      onError: (error) => {
        if (error) setGpsError(t('common.errorMsgGps'));
      },
    },
  );

  const ownerOptions: IDropdownOption[] = useMemo(
    () => [
      { value: 'owned', label: t('mobility.owned') },
      { value: 'rented', label: t('mobility.rented') },
      { value: 'external_provide', label: t('mobility.externalProvider') },
    ],
    [t],
  );

  const paxOptions: IDropdownOption[] = createPaxOptions();

  const protectionOptions: IDropdownOption[] = useMemo(
    () => [
      { value: 'soft', label: t('common.soft') },
      { value: 'armoured', label: t('common.armoured') },
    ],
    [t],
  );

  const handleSubmit = (formState: FormState) => {
    const state = { ...(formState.values as unknown as FormStateProps) };
    const data = {
      chassis_number: state.chassisNumber || '',
      gvlp_number: state.gvlpNumber || null,
      max_capacity: Number(state.maxCapacity.value),
      model: state.vehicleModel.value,
      plate_number: state.plateNumber,
      protection: state.protection.value as VehicleProtection,
      roof_number: state.roofNumber || null,
      ...(state.preferredDriver?.value ? { preferred_driver: state.preferredDriver.value } : {}),
      ...(state.vehicleOwner ? { vehicle_owner: state.vehicleOwner.value as VehicleOwner } : {}),
      ...(isEditProcess
        ? {
            shared_locations: (state.sharedLocations || [])?.map(
              Serializer.mapSharedLocationToUpdate,
            ),
          }
        : {}),
    };

    if (isEditProcess) {
      updateVehicle({ vehicleId: details?.id!, data });
    } else {
      createVehicle({ agency: facility?.agencyId, model_fk: state.vehicleModel.value, ...data });
    }
  };

  const {
    agency,
    chassisNumber,
    gvlpNumber,
    label,
    locations,
    maxCapacity,
    model,
    modelChoices,
    plateNumber,
    preferredDriver,
    protection,
    roofNumber,
    vehicleOwner,
    sharedLocationOptions,
    sharedLocations,
  } = details || {};

  const initFormData: Record<string, unknown> = {
    agency: isEditProcess ? agency?.shortName ?? '' : '',
    chassisNumber: isEditProcess ? chassisNumber : '',
    gvlpNumber: isEditProcess ? gvlpNumber : '',
    maxCapacity: isEditProcess ? paxOptions?.find((o) => +o.value === maxCapacity) : undefined,
    plateNumber: isEditProcess ? plateNumber : '',
    preferredDriver: isEditProcess ? preferredDriver : '',
    protection: isEditProcess ? protectionOptions?.find((o) => o.value === protection) : undefined,
    roofNumber: isEditProcess ? roofNumber : '',
    sharedLocations: isEditProcess ? sharedLocations : [],
    vehicleModel: isEditProcess ? modelChoices?.find((o) => o.value === model?.id) : undefined,
    vehicleOwner: isEditProcess ? ownerOptions.find((o) => o.value === vehicleOwner) : undefined,
  };

  const ownedAgency = locations?.length ? locations[0].agency : agencyName;
  const ownedCity = locations?.length ? locations[0].city : city;
  const ownedCountry = locations?.length ? locations[0].country : country;

  return (
    <Loader spinning={isVehicleDetailsLoading || isLoading}>
      <Form
        className="form-vehicle-edit modal-light-form"
        initialValues={initFormData}
        onSubmit={handleSubmit}
      >
        <h2>{isEditProcess ? t('mobility.editVehicle') : t('mobility.addVehicle')}</h2>

        {isEditProcess && (
          <div className="row vehicle">
            <div className="vehicle-photo">
              {model?.image ? (
                <img src={model.image} alt={model.name} />
              ) : (
                <div className="vehicle-photo-placeholder">
                  <VehiclePlaceholder />
                </div>
              )}
            </div>

            <div className="vehicle-info">
              <div className="name">{model ? model.name : label}</div>
              {!readOnly ? (
                <div className="gps-provider">
                  {isGPSConnected ? (
                    <>
                      <Button
                        className="btn-connected"
                        icon={<CheckMark />}
                        text={t('mobility.btnConnected')}
                        variant="submit"
                      />
                      <Button
                        className="btn-disconnect"
                        text={t('mobility.btnDisconnect')}
                        variant="link"
                        onClick={onGPSConnection}
                      />
                    </>
                  ) : (
                    <>
                      {isGPSLoading ? (
                        <Button variant="secondary" text="Connecting…" />
                      ) : (
                        <Button
                          icon={<GPSConnect />}
                          text={t('mobility.btnConnectToGPS')}
                          variant="primary"
                          onClick={onGPSConnection}
                        />
                      )}
                      <div className="gps-error">{gpsError}</div>
                    </>
                  )}
                </div>
              ) : null}
            </div>
            <PreferredDriverField disabled={readOnly} options={driverOptions} />
          </div>
        )}

        <h3>{t('mobility.carInfo')}</h3>
        <div className="row">
          <VehicleModelField
            disabled={isEditProcess}
            options={isEditProcess ? modelChoices! : vehicleModelOptions!}
          />
          <VehicleChassisNumberField disabled={readOnly} />
          {isEditProcess && <VehicleAgencyField disabled={isEditProcess} />}
          <VehiclePlateNumberField disabled={readOnly} />
        </div>

        <div className="row">
          <VehicleRoofNumberField disabled={readOnly} />
          <VehicleGvlpNumberField disabled={readOnly} />
          <VehiclePaxField disabled={readOnly} options={paxOptions} />
          {isEditProcess && <VehicleOwnerField disabled={readOnly} options={ownerOptions} />}
          <VehicleProtectionField disabled={readOnly} options={protectionOptions} />
        </div>

        {isEditProcess && (
          <>
            <h3>{t('mobility.shared')}</h3>
            <div className="row">
              <VehicleSharedAgencyField disabled={readOnly} options={sharedLocationOptions} />
            </div>
          </>
        )}

        <div className="footer">
          <div className="info">
            {t('mobility.ownedBy')}: {ownedCity}, {ownedCountry} - {ownedAgency}
          </div>

          {!readOnly ? (
            <Button
              className="btn btn-submit"
              disabled={isLoading}
              text={t('common.btnConfirmChanges')}
              type="submit"
              variant="submit"
            />
          ) : null}
        </div>
      </Form>
    </Loader>
  );
};

export default VehicleEditForm;
