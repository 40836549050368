/* istanbul ignore file */
/* eslint-disable no-nested-ternary */
import { type FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ArrayField, Multistep, useFormApi, useFormState, useMultistepApi } from 'informed';

import { IDropdownOption } from '@ui-modules/types';
import {
  Drive,
  DriveFormSteps as Steps,
  DriveFormState,
  PlDrive,
  PlDriveFormData,
  PlVehicle,
  ShuttleStop,
  StepOptions,
  TransferType,
  TripType,
} from '@common/interfaces';
import {
  calcSharedDrive,
  checkConflictDrives,
  checkShuttleConflictRange,
  checkShuttleType,
  getFullDate,
  VerifiedTransferType,
} from '@common/utils';
import { useFacility, useFieldState } from '@common/hooks';
import { Button } from '@components';
import { Add, ArrowLeft, ArrowRight, Check } from '@assets/svg/icons';

import RecurDrive from './RecurDrive';
import {
  ButtonSubmit,
  CommentField,
  DriverField,
  DropoffDateField,
  DropoffLocationField,
  DropoffTimeField,
  DropoffTownField,
  PickupDateField,
  PickupLocationField,
  PickupTimeField,
  PickupTownField,
  PurposeField,
  TransferTypeField,
  TripTypeField,
  VehicleField,
} from '../../../fields';
import { findOption } from '../../../utils';
import '../../../styles/modal.scss';

const multiLegLimit = 20;
let multiLegCount = 0;

interface StepProps {
  drive?: Drive;
  drives: PlDrive[];
  formData: PlDriveFormData | null;
  initMultileg: Record<string, unknown>;
  options: StepOptions;
  scheduledStops?: ShuttleStop[];
  vehicles: PlVehicle[];
  setStepReturnTripEnabled: (value: boolean) => void;
}

const TripStep: FC<StepProps> = ({
  drive,
  drives,
  formData,
  initMultileg,
  options,
  scheduledStops,
  vehicles,
  setStepReturnTripEnabled,
}) => {
  const formApi = useFormApi();
  const { t } = useTranslation();
  const { facility } = useFacility();
  const { values } = useFormState() as any;
  const { next, setCurrent } = useMultistepApi();
  const { driverOpt, locOpt, purposeOpt, tripTypeOpt, vehicleOpt } = options;

  const [verifiedTransfer, setVerifiedTransfer] = useState<VerifiedTransferType | null>(null);

  const { value: transferType } = useFieldState<TransferType>(`${Steps.Trip}.transferType`);
  const { value: tripType } = useFieldState<IDropdownOption>(`${Steps.Trip}.typeOfTrip`);
  const { bookedRouteOrder, driver, id } = drive! || {};

  const isEditProcess = !!formData?.id;
  const isRoundTrip = tripType?.value === TripType.RoundTrip;
  const isMultiLeg = tripType?.value === TripType.MultiLeg && transferType !== TransferType.Airport;
  const isShuttle = transferType === TransferType.Shuttle;
  const fieldGroup = isMultiLeg ? 'driveListMultileg' : 'driveList';
  const formState = { ...(values as DriveFormState)?.[Steps.Trip] };
  const {
    driver: driverId,
    dropoffDate,
    dropoffTime,
    pickupDate,
    pickupTime,
    vehicle,
  } = formState[fieldGroup]?.[0] || {};

  const sharedDrive = calcSharedDrive({
    drives: drives.filter((i: PlDrive) => i.id !== id),
    dropoffDate: dropoffDate!,
    dropoffTime: dropoffTime!,
    pickupDate: pickupDate!,
    pickupTime: pickupTime!,
    vehicle: vehicle!,
  });

  const onVerifiedClick = () => {
    const conflict = checkConflictDrives({
      driver: driverId!,
      drives: drives.filter((i: PlDrive) => i.id !== id),
      dropoffDate: dropoffDate!,
      dropoffTime: dropoffTime!,
      pickupDate: pickupDate!,
      pickupTime: pickupTime!,
      vehicle: vehicle!,
    });

    if (conflict) {
      const errorMsg =
        conflict === 'driver'
          ? t('planner.msgConflictDrives')
          : `${t('bookingDetails.selected')} ${conflict} ${t('bookingDetails.msgConflictMulti')}`;

      return toast.error(errorMsg);
    }

    setCurrent(Steps.Confirm);
  };

  useEffect(() => {
    if (formData?.scheduled) {
      formApi.setValue(
        `${Steps.Trip}.purpose`,
        findOption(purposeOpt, formData?.scheduled?.bookingPurpose!),
      );
      formApi.setValue(
        `${Steps.Trip}.typeOfTrip`,
        formData?.scheduled?.bookingTypeOfTrip || TripType.OneWay,
      );
    }
  }, [formApi, formData?.scheduled, purposeOpt]);

  useEffect(() => {
    if (dropoffDate && dropoffTime && pickupDate && pickupTime && vehicle) {
      const verified = checkShuttleType({
        drives: drives.filter((i: PlDrive) => i.id !== id),
        end: getFullDate(dropoffDate!, dropoffTime!),
        start: getFullDate(pickupDate!, pickupTime!),
        transferType,
        vehicle: vehicle?.value!,
      });

      if (verified) {
        if (verified.transferType === TransferType.Shuttle) {
          const isShuttleConflict = checkShuttleConflictRange({
            driver: driverId?.value!,
            drives,
            end: getFullDate(dropoffDate, dropoffTime),
            start: getFullDate(pickupDate, pickupTime),
            vehicle: vehicle.value!,
          });

          if (isShuttleConflict) {
            if (isShuttleConflict === 'driver') toast.error(t('planner.warnShuttleDriverConflict'));
            if (isShuttleConflict === 'time') toast.error(t('planner.warnShuttleConflict'));
          }
        }

        if (verified.transferType === TransferType.Shuttle && !verified.typeChanged) return;

        setVerifiedTransfer(verified);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverId, dropoffDate, dropoffTime, pickupDate, pickupTime, vehicle]);

  useEffect(() => {
    if (tripType) setStepReturnTripEnabled(tripType?.value === TripType.RoundTrip);
  }, [setStepReturnTripEnabled, tripType]);

  const getFieldsSection = (): JSX.Element => {
    const sectionTitle =
      transferType !== TransferType.Airport && isRoundTrip
        ? isEditProcess
          ? bookedRouteOrder === 1
            ? t('bookingDetails.outwardTrip')
            : t('bookingDetails.returnTrip')
          : `1. ${t('bookingDetails.outwardTrip')}`
        : null;

    let fieldset: JSX.Element;

    if (!isMultiLeg) {
      fieldset = (
        <ArrayField name={fieldGroup}>
          {() => (
            <ArrayField.Items>
              {({ index, name }) => {
                const isReturnTrip = !isMultiLeg && index === 1;
                const data = formState?.[fieldGroup];

                if (data && index >= data?.length) return <Fragment key={name} />;

                return (
                  <Fragment key={name}>
                    {sectionTitle && <div className="section-title">{sectionTitle}</div>}

                    <div className="row field-group">
                      <PickupDateField fieldGroup={name} />
                      <PickupLocationField
                        fieldGroup={name}
                        index={index}
                        isEdit={isEditProcess}
                        options={locOpt}
                        returnTrip={isReturnTrip}
                        scheduled={scheduledStops}
                      />
                      <PickupTimeField fieldGroup={name} scheduled={scheduledStops} />
                      <PickupTownField fieldGroup={name} />
                      <DropoffDateField />
                      <DropoffLocationField
                        fieldGroup={name}
                        index={index}
                        isEdit={isEditProcess}
                        returnTrip={isReturnTrip}
                        options={locOpt}
                        scheduled={scheduledStops}
                      />
                      <DropoffTimeField fieldGroup={name} scheduled={scheduledStops} />
                      <DropoffTownField fieldGroup={name} />
                      <VehicleField options={vehicleOpt} />
                      <DriverField
                        driver={driver}
                        options={driverOpt}
                        fieldGroup={name}
                        isEdit={isEditProcess}
                        isShuttle={isShuttle}
                        sharedDrive={sharedDrive}
                        vehicles={vehicles}
                      />
                      <CommentField label={t('planner.commentPax')} name="commentToPax" />
                      <CommentField label={t('planner.commentDriver')} name="commentToDriver" />
                    </div>
                  </Fragment>
                );
              }}
            </ArrayField.Items>
          )}
        </ArrayField>
      );
    } else {
      fieldset = (
        <ArrayField name={fieldGroup}>
          {({ addWithInitialValue }) => (
            <>
              <ArrayField.Items>
                {({ name, remove, index }) => {
                  multiLegCount = index + 2;
                  return (
                    <Fragment key={name}>
                      {isMultiLeg && (
                        <div className="section-title">
                          Leg {isEditProcess ? bookedRouteOrder : index + 1}
                          {index > 1 && !isEditProcess && (
                            <Button
                              className="btn-multileg-remove"
                              leftIcon={<div>&#215;</div>}
                              variant="icon"
                              onClick={remove}
                            />
                          )}
                        </div>
                      )}

                      <div className="field-group">
                        <PickupDateField fieldGroup={name} />
                        <PickupTimeField fieldGroup={name} />
                        <PickupTownField fieldGroup={name} />
                        <PickupLocationField fieldGroup={name} index={index} />
                        <DropoffDateField />
                        <DropoffTimeField fieldGroup={name} />
                        <DropoffTownField fieldGroup={name} />
                        <DropoffLocationField fieldGroup={name} index={index} />
                        <VehicleField options={vehicleOpt} />
                        <DriverField
                          driver={driver}
                          options={driverOpt}
                          fieldGroup={name}
                          isEdit={isEditProcess}
                          sharedDrive={sharedDrive}
                          vehicles={vehicles}
                        />
                      </div>

                      <div className="row">
                        <CommentField label={t('planner.commentPax')} name="commentToPax" />
                        <CommentField label={t('planner.commentDriver')} name="commentToDriver" />
                      </div>
                    </Fragment>
                  );
                }}
              </ArrayField.Items>

              {isMultiLeg && !isEditProcess && multiLegCount <= multiLegLimit && (
                <div className="row">
                  <Button
                    className="btn-multileg-add"
                    leftIcon={<Add />}
                    text={t('planner.addLeg')}
                    variant="primary"
                    onClick={() => addWithInitialValue(initMultileg)}
                  />
                </div>
              )}
            </>
          )}
        </ArrayField>
      );
    }

    return fieldset;
  };

  const getNextBtn = (): JSX.Element | null => {
    if (!transferType) return null;
    if (verifiedTransfer) {
      return (
        <Button
          leftIcon={<Check />}
          text={t('common.btnConfirm')}
          variant="submit"
          onClick={onVerifiedClick}
        />
      );
    }
    if (isEditProcess) return <ButtonSubmit />;
    if (isRoundTrip) {
      return (
        <Button
          data-testid="planner-drive-form-btn-next"
          rightIcon={<ArrowRight />}
          text={t('common.btnNext')}
          variant="primary"
          onClick={next}
        />
      );
    }
    if (!isRoundTrip && !isShuttle && sharedDrive) {
      return (
        <Button
          data-testid="planner-drive-form-btn-save"
          leftIcon={<Check />}
          text={t('common.btnConfirm')}
          type="submit"
          variant="submit"
          onClick={next}
        />
      );
    }

    return <ButtonSubmit />;
  };

  const showRecurringBtn =
    !isEditProcess &&
    (transferType === TransferType.Airport || (tripType && tripType?.value !== TripType.RoundTrip));

  return (
    <Multistep.Step step={Steps.Trip}>
      <nav className="steps">
        <Button
          className="btn"
          data-testid="planner-drive-form-btn-main-info"
          text={t('planner.mainInfo')}
          variant="transparent"
          onClick={() => setCurrent(Steps.Main)}
        />
        <Button
          className="btn"
          data-testid="planner-drive-form-btn-trip-info"
          disabled
          text={t('planner.tripInfo')}
          variant="transparent"
          onClick={() => setCurrent(Steps.Trip)}
        />
      </nav>

      <section className={`fieldset ${isMultiLeg ? 'multi-leg' : ''}`}>
        <div className="row">
          <TransferTypeField
            city={facility.city}
            fieldGroup={fieldGroup}
            isEdit={isEditProcess}
            isScheduled={!!formData?.scheduled}
          />
        </div>

        {transferType && (
          <>
            {transferType !== TransferType.Airport && (isEditProcess || !isShuttle) && (
              <div className="row">
                <TripTypeField isEdit={isEditProcess} options={tripTypeOpt} />
              </div>
            )}

            {(tripType || transferType === TransferType.Airport) && (
              <>
                {getFieldsSection()}

                {transferType !== TransferType.Airport && (
                  <div className="row">
                    <PurposeField options={purposeOpt} />
                  </div>
                )}

                {values.addRecurring &&
                (transferType === TransferType.Airport ||
                  tripType?.value !== TripType.RoundTrip) ? (
                  <div className="row">
                    <RecurDrive
                      currentDate={formData?.resDate}
                      isMultileg={isMultiLeg}
                      step={Steps.Trip}
                    />
                  </div>
                ) : null}
              </>
            )}

            <footer>
              <Button
                className="btn-back"
                data-testid="planner-drive-form-btn-back"
                leftIcon={<ArrowLeft />}
                text={t('common.btnBack')}
                variant="transparent"
                onClick={() => setCurrent(Steps.Main)}
              />

              <div>
                {!isShuttle &&
                  showRecurringBtn &&
                  (values.addRecurring ? (
                    <Button
                      onClick={() => formApi.setValue('addRecurring', false)}
                      text={t('common.recurring.cancelRecurringBtn')}
                      variant="primary"
                    />
                  ) : (
                    <Button
                      onClick={() => formApi.setValue('addRecurring', true)}
                      text={t('common.recurring.addRecurringBtn')}
                      variant="primary"
                    />
                  ))}

                {getNextBtn()}
              </div>
            </footer>
          </>
        )}
      </section>
    </Multistep.Step>
  );
};

export default TripStep;
