import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { Form, FormState } from 'informed';

import { Button } from '@unbooking/ui-modules';
import { IDropdownOption } from '@ui-modules/types';
import { useRepository } from '@context';
import { useFacility, useVehicles } from '@common/hooks';
import { Serializer, formatStringToDate } from '@common/utils';
import { DriverCreate, DriverDetails, DriverDetailsApi, DriverUpdate } from '@common/interfaces';
import { Loader } from '@components';
import { DriverPlaceholder } from '@assets/svg/icons';
import {
  DriverBloodGroupField,
  DriverExpirationDateField,
  DriverLicenseNumberField,
  DriverSharedAgencyField,
  EmailField,
  FirstNameField,
  LastNameField,
  PhoneNumberField,
  PreferredVehicleField,
} from '../fields';
import './styles.scss';

const bloodGroupOptions: IDropdownOption[] = [
  { value: '0', label: 'A+' },
  { value: '1', label: 'A-' },
  { value: '2', label: 'B+' },
  { value: '3', label: 'B-' },
  { value: '4', label: 'O+' },
  { value: '5', label: 'O-' },
  { value: '6', label: 'AB+' },
  { value: '7', label: 'AB-' },
];

export interface FormStateProps {
  bloodGroup: IDropdownOption;
  city: string;
  country: string;
  email: string;
  lastName: string;
  licenseExpirationDate: Date;
  licenseNumber: string;
  name: string;
  phoneNumber: string;
  preferredVehicle: IDropdownOption;
  sharedLocations: IDropdownOption[];
}

export interface DriverEditFormProps {
  driverId: string | null;
  isLoading: boolean;
  readOnly?: boolean;
  createDriver: (data: DriverCreate) => void;
  updateDriver: ({ driverId, data }: { driverId: string; data: DriverUpdate }) => void;
}

const DriverEditForm: FC<DriverEditFormProps> = ({
  driverId,
  isLoading,
  readOnly = false,
  createDriver,
  updateDriver,
}) => {
  const { t } = useTranslation();
  const { mobilityRepository } = useRepository();
  const { vehicleOptions } = useVehicles();
  const { agencyName, facility, facilityId: fid } = useFacility();
  const { city, country } = facility;
  const isEditProcess = !!driverId;

  const [driverDetails, setDriverDetails] = useState<DriverDetails | null>(null);

  const { isFetching: isDriverDetailsLoading } = useQuery(
    'get-driver-details',
    () => mobilityRepository.getDriverDetails(fid, driverId!, { agencyId: facility?.agencyId }),
    {
      enabled: isEditProcess,
      onSuccess: (data: DriverDetailsApi) => {
        setDriverDetails(Serializer.formatDriverDetails(data));
      },
      onError: (error: AxiosError) => {
        if (error.message) toast.error(error.message || t('common.errorMessageDefault'));
      },
    },
  );

  const handleSubmit = (formState: FormState) => {
    const state = { ...(formState.values as unknown as FormStateProps) };
    const data = {
      email: state.email,
      last_name: state.lastName,
      license_expiration_date:
        state.licenseExpirationDate && state.licenseNumber
          ? format(state.licenseExpirationDate, 'yyyy-MM-dd')
          : null,
      license_number: state.licenseNumber || null,
      name: state.name,
      phone_number: state.phoneNumber,
      ...(state.bloodGroup?.value ? { blood_group: Number(state.bloodGroup.value) } : {}),
      ...(state.preferredVehicle?.value ? { preferred_vehicle: state.preferredVehicle.value } : {}),
      ...(isEditProcess
        ? {
            shared_locations: (state.sharedLocations || [])?.map(
              Serializer.mapSharedLocationToUpdate,
            ),
          }
        : {}),
    };

    if (isEditProcess) {
      updateDriver({ driverId: driverDetails?.id!, data });
    } else {
      createDriver({ agency: facility?.agencyId, ...data });
    }
  };

  const {
    bloodGroupDisplay,
    email,
    lastModifiedDate,
    lastName,
    licenseExpirationDate,
    licenseNumber,
    locations,
    name,
    phoneNumber,
    photo,
    preferredVehicle,
    sharedLocationOptions,
    sharedLocations,
  } = driverDetails || {};

  const initFormData: Record<string, unknown> = {
    email: isEditProcess ? email : '',
    name: isEditProcess ? name : '',
    lastName: isEditProcess ? lastName : '',
    licenseExpirationDate:
      isEditProcess && licenseExpirationDate
        ? formatStringToDate(licenseExpirationDate, 'dd/MM/yyyy')
        : undefined,
    licenseNumber: isEditProcess ? licenseNumber : '',
    phoneNumber: isEditProcess ? phoneNumber : '',
    preferredVehicle: isEditProcess ? preferredVehicle : undefined,
    sharedLocations: isEditProcess ? sharedLocations : '',
  };

  const ownedAgency = locations?.length ? locations[0].agency : agencyName;
  const ownedCity = locations?.length ? locations[0].city : city;
  const ownedCountry = locations?.length ? locations[0].country : country;

  return (
    <Loader spinning={isDriverDetailsLoading || isLoading}>
      <Form
        className="form-driver-edit modal-light-form"
        initialValues={initFormData}
        onSubmit={handleSubmit}
      >
        <h2>{isEditProcess ? t('mobility.editDriver') : t('mobility.addDriver')}</h2>

        {isEditProcess && (
          <div className="row driver">
            <div className="driver-photo">
              {photo ? (
                <img src={photo} alt="" />
              ) : (
                <div className="driver-photo-placeholder">
                  <DriverPlaceholder />
                </div>
              )}
            </div>

            <div className="driver-info">
              <div className="name">
                {name} {lastName}
              </div>
              {sharedLocations?.length! > 0 && (
                <div className="shared">
                  {t('mobility.shared')}
                  <span className="shared-label">
                    {sharedLocations?.length} {t('common.agencies')}
                  </span>
                </div>
              )}
            </div>

            {bloodGroupDisplay && (
              <DriverBloodGroupField disabled={readOnly} options={bloodGroupOptions} />
            )}

            <PreferredVehicleField disabled={readOnly} options={vehicleOptions} />
          </div>
        )}

        <h3>{t('mobility.personalInfo')}</h3>
        <div className="row">
          <FirstNameField disabled={readOnly} />
          <LastNameField disabled={readOnly} />
          <EmailField
            disabled={readOnly}
            label={isEditProcess ? t('common.changeEmail') : t('common.email')}
          />
          <PhoneNumberField
            disabled={readOnly}
            label={isEditProcess ? t('common.changePhoneNumber') : t('common.phoneNumber')}
          />
        </div>

        <div className="row driver">
          <div className="driver-license">
            <h3>{t('mobility.driverLicense')}</h3>
            <div className="row">
              <DriverLicenseNumberField disabled={readOnly} />
              <DriverExpirationDateField disabled={readOnly} />
            </div>
          </div>
        </div>

        {isEditProcess && (
          <>
            <h3>{t('mobility.shared')}</h3>
            <div className="row">
              <DriverSharedAgencyField disabled={readOnly} options={sharedLocationOptions} />
            </div>
          </>
        )}

        <div className="footer">
          <div className="info">
            {t('mobility.ownedBy')}: {ownedCity}, {ownedCountry} - {ownedAgency}
            {isEditProcess ? ` (${t('mobility.lastModified')} ${lastModifiedDate})` : null}
          </div>

          {!readOnly ? (
            <Button
              className="btn btn-submit"
              text={t('common.btnConfirmChanges')}
              type="submit"
              variant="submit"
            />
          ) : null}
        </div>
      </Form>
    </Loader>
  );
};

export default DriverEditForm;
