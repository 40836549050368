/* istanbul ignore file */
import { useEffect } from 'react';
import { t } from 'i18next';
import { addMinutes, isAfter, isEqual, isSameDay, parse, setHours, setMinutes } from 'date-fns';
import { useFormApi } from 'informed';
import { IDropdownOption } from '@ui-modules/types';
import { DateField } from '@ui-modules/informed';
import { TIME_INTERVAL } from '@common/constants';
import { DriveFormSteps, ShuttleStop } from '@common/interfaces';
import { useFieldState } from '@common/hooks';
import { notEmptyValidator } from '@common/utils';
import '../styles/modal.scss';

interface FieldProps {
  fieldGroup?: string;
  index?: number;
  returnTrip?: boolean;
  scheduled?: ShuttleStop[];
}

const PickupTimeField = ({ fieldGroup, index, returnTrip = false, scheduled }: FieldProps) => {
  const formApi = useFormApi();
  const { value: dropoffTime } = useFieldState<Date>('dropoffTime');
  const { value: pickupDate } = useFieldState<Date>('pickupDate');
  const { value: pickupLocation } = useFieldState<IDropdownOption>('pickupLocation');
  const formState = formApi.getFormState().values as any;
  const drives = formState?.[DriveFormSteps.Trip]?.driveList;
  const dropDate = drives && typeof index === 'number' ? drives[index]?.dropoffDate : null;
  const dropTime = drives && typeof index === 'number' ? drives[index]?.dropoffTime : null;
  const isSameDate = dropDate && isSameDay(pickupDate, dropDate);

  let confTime: string | undefined;
  if (scheduled && pickupLocation) {
    confTime = scheduled.find((i) => i.location === pickupLocation?.value)?.pickupTime;
  }

  useEffect(() => {
    if (returnTrip && dropTime) {
      formApi.setValue(fieldGroup ? `${fieldGroup}.pickupTime` : 'pickupTime', dropTime);
    }
  }, [dropTime, fieldGroup, formApi, returnTrip]);

  useEffect(() => {
    if (scheduled && pickupLocation) {
      const time = parse(confTime || scheduled[0].pickupTime, 'HH:mm', new Date());
      formApi.setValue(fieldGroup ? `${fieldGroup}.pickupTime` : 'pickupTime', time);
    }
  }, [confTime, fieldGroup, formApi, pickupLocation, scheduled]);

  return (
    <div className="field field-time" data-testid="planner-drive-form-field-pickup-time">
      <DateField
        className="input"
        dateFormat="HH:mm"
        label={t('bookingDetails.pickupTime')}
        maxTime={isSameDate ? setHours(setMinutes(new Date(), 45), 23) : undefined}
        minTime={isSameDate ? dropTime : undefined}
        name="pickupTime"
        readOnly={!!confTime}
        showTimeSelect
        showTimeSelectOnly
        timeCaption={undefined}
        timeFormat="HH:mm"
        timeIntervals={TIME_INTERVAL}
        validate={notEmptyValidator}
        onChange={(value: Date | string) => {
          if (confTime) return;
          if (isAfter(value as Date, dropoffTime) || isEqual(value as Date, dropoffTime)) {
            formApi.setValue(
              fieldGroup ? `${fieldGroup}.dropoffTime` : 'dropoffTime',
              addMinutes(value as Date, TIME_INTERVAL),
            );
          }
        }}
      />
    </div>
  );
};

export default PickupTimeField;
